import { createI18n } from "vue-i18n/index";

const i18n = createI18n({
  locale: "en",
  legacy: true,
  fallbackLocale: "en",
  messages: {
    en: require("@/lang/en.json"),
    km: require("@/lang/km.json"),
  },
});
export async function loadMessage(locale) {
  if (Object.keys(i18n.global.getLocaleMessage(locale)).length === 0) {
    const messages = await import(`@/lang/${locale}.json`);

    i18n.global.setLocaleMessage(locale, messages);
  }
  if (i18n.mode === "legacy") {
    if (i18n.global.locale != locale) i18n.global.locale = locale;
  } else {
    if (i18n.global.locale.value != locale) i18n.global.locale.value = locale;
  }
  document.querySelector("html").setAttribute("lang", locale);
}

export default i18n;
