import { cartList } from "@/api/CartApi";
import { getCartUuid, getStore } from "@/api/StoreApi";
import useNotyf from "@/composable/useNotyf";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import { computed } from "vue";
// import { useI18n } from "vue-i18n/index";
import i18n from "@/i18n";

const audioMouseWheelClick = new Audio(
  require("@/assets/sfx/mouse_wheel_button_click.mp3")
);

const audioPenClick = new Audio(require("@/assets/sfx/pen_click.mp3"));
const audioMouseClick = new Audio(require("@/assets/sfx/mouse_click.mp3"));
const audioAddToCart = new Audio(require("@/assets/sfx/add_to_cart.mp3"));
const audioRemoveCart = new Audio(require("@/assets/sfx/remove_cart.mp3"));

export const showLoading = (submitButton) => {
  setTimeout(() => {
    submitButton.value.setAttribute("data-kt-indicator", "on");
  }, 0);
};

export const hideLoading = (submitButton) => {
  setTimeout(() => {
    submitButton.value?.removeAttribute("data-kt-indicator");
  }, 250);
};

export const successButton = (submitButton, newTitle = "Success") => {
  const titleSpan = submitButton.value.querySelector(".indicator-label");
  const oldTitle = titleSpan.textContent;
  setTimeout(() => {
    titleSpan.textContent = oldTitle;
  }, 1000);
  titleSpan.textContent = newTitle;
};

export const shakeButton = (submitButton) => {
  submitButton.value.classList.add(
    "animate__animated",
    "animate__shakeX",
    "animate__faster"
  );
  submitButton.value?.addEventListener("animationend", () => {
    submitButton.value.classList.remove(
      "animate__animated",
      "animate__shakeX",
      "animate__faster"
    );
  });
};

export const initStore = async () => {
  return await getStore();
};

export const getCurrentStore = async () => {
  return JSON.parse(localStorage.getItem("currentStore") ?? "{}");
};

export const notification = (payload) => {
  const notification = useNotyf();
  if (payload.type === "success") {
    notification.success(i18n.global.t(payload?.message));
  } else {
    notification.error(i18n.global.t(payload?.message));
  }
};

export function getParentRouteName(routePath) {
  const routeSplit = routePath.split("/");
  const len = routeSplit.length;
  return routeSplit[len - 2];
}

// return a promise
export function copyToClipboard(textToCopy) {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        notification({
          type: "success",
          message: "Link Copied",
        });
      })
      .catch(() => {
        notification({
          type: "error",
          message: "Link Copied Fail",
        });
      });
  } else {
    return new Promise((resolve, reject) => {
      notification({
        type: "error",
        message: "Link Copied Fail",
      });
      reject();
    });
  }
}

export function topLoadingToggle(val) {
  store.commit(Mutations.TOGGLE_GLOBAL_LOADING, val);
}

export const getCartList = async () => {
  await cartList({})
    .then((res) => {
      let countProduct = 0;
      const products = res.data.data.products ?? [];

      for (let i = 0; i < products.length; i++) {
        countProduct += products[i].quantity;
      }

      res.data.data["countItem"] = countProduct;

      store.commit(Mutations.REFRESH_CART_DATA, res.data.data);
    })
    .catch((error) => {
      console.log(error);
      store.commit(Mutations.SET_TOGGLE_SIDEBAR, false);
    });
};

export const setCartTokenHeader = async (payload) => {
  if (store.getters.isUserAuthenticated) {
    store.commit(Mutations.SET_CART_UUID, "");
    localStorage.removeItem("cartUuid");
  } else {
    if (!(await isCartUuidExist())) {
      await getCartUuid(payload).then(async ({ data }) => {
        store.commit(Mutations.SET_CART_UUID, data?.data?.uuid);
        await asyncLocalStorage
          .setItem("cartUuid", data?.data?.uuid)
          .then(() => "_");
      });
    } else {
      await asyncLocalStorage.getItem("cartUuid").then((res) => {
        store.commit(Mutations.SET_CART_UUID, res);
      });
    }
  }
};

async function isCartUuidExist() {
  return await asyncLocalStorage.getItem("cartUuid").then((res) => {
    return res !== null;
  });
}

export const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  },
  removeItem: function (key) {
    return Promise.resolve().then(function () {
      localStorage.removeItem(key);
    });
  },
};

export const screenWidth = () => {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  );
};

export const globalVibrate = (value) => {
  // not yet support in ios
  // navigator.vibrate(value);
};

export const audioPlayClick = (
  type: "MouseWheel" | "Pen" | "Mouse" | "AddToCart" | "RemoveCart"
) => {
  const isSound = computed(() => {
    return store.getters.getStoreData?.is_sound;
  });

  if (isSound.value) {
    globalVibrate(75);
    const audioArray = {
      MouseWheel: audioMouseWheelClick,
      Pen: audioPenClick,
      Mouse: audioMouseClick,
      AddToCart: audioAddToCart,
      RemoveCart: audioRemoveCart,
    };

    audioArray[type].currentTime = 0.15;
    audioArray[type].play();
  }
};

export const translate = (text: string) => {
  if (i18n.global.te(text)) {
    return i18n.global.t(text);
  } else {
    return text;
  }
};

export const scrollUpToClass = (className) => {
  setTimeout(() => {
    const firstError = document.getElementsByClassName(className);
    if (firstError.length > 0) {
      firstError[0].scrollIntoView({
        behavior: "smooth",
      });
    }
  }, 250);
};

// export const reverseAddress = (center) => {
//   return new Promise<google.maps.GeocoderResult[]>((resolve) => {
//     const geocoder = new google.maps.Geocoder();
//     geocoder.geocode({ location: center.value }, (res) => {
//       console.log("%cForm.vue line:366 res", "color: #007acc;", res);
//     });
//   });
// };
