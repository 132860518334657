import { createApp } from "vue";
/*
TIP: To get started with clean router change path to @/router/clean.ts.
*/
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "./i18n";
import VueSocialSharing from "vue-social-sharing";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
// import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import VueUniversalModal from "vue-universal-modal";

// import axiosConfig from "@/core/services/ApiAxios";

// import "@/core/plugins/prismjs";
import "bootstrap";
import App from "./App.vue";

// Default theme
// import "@splidejs/vue-splide/dist/css/splide.min.css";

//app style
import "vue-universal-modal/dist/index.css";
import "vue-universal-modal/dist/index.css";
import "@vueform/toggle/themes/default.css";
import "@/assets/css/app.css";
import "@/assets/css/custom_animation.css";
import "@/assets/css/utility.css";
import { Actions, Mutations } from "./store/enums/StoreEnums";
import {
  asyncLocalStorage,
  initStore,
  setCartTokenHeader,
} from "./core/helpers/custom";

const GOOGLE_MAP_KEY = process.env.VUE_APP_GOOGEL_MAP_KEY;

(async () => {
  const app = createApp(App);
  app.use(store);
  app.use(router);
  app.use(ElementPlus);
  app.use(VueSocialSharing);
  app.use(i18n);
  app.use(
    VueUniversalModal,
    app.use(VueUniversalModal, {
      teleportTarget: "#modals",
    })
  );
  app.use(VueGoogleMaps, {
    load: {
      key: GOOGLE_MAP_KEY,
      libraries: "places",
      // language: 'de',
    },
  });
  await initStore()
    .then(async ({ data }) => {
      const favicon = document.getElementById("favicon") as HTMLLinkElement;
      store.commit(Mutations.SET_STORE_DATA, data?.data);
      document.title = store.getters.getStoreData?.store_name;
      favicon.href = store.getters.getStoreData?.store_icon;
      await asyncLocalStorage
        .setItem("currentStore", JSON.stringify(store.getters.getStoreData))
        .then(async () => {
          await setCartTokenHeader(store.getters.getStoreData.id);
        });
    })
    .catch(() => {
      router.push({ name: "404" });
    }); //API promises
  ApiService.init(app);
  // initApexCharts(app);
  initInlineSvg(app);
  initVeeValidate();
  await store.dispatch(Actions.VERIFY_AUTH, {});

  // router.isReady().then(() => app.mount("#app"));
  app.mount("#app");
  // the rest of the code
})();
