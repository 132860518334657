enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGIN_BY_SOCIAL = "loginBySocial",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  UPDATE_USER_PASSWORD = "updateUserPassword",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  TOGGLE_SIDEBAR = "toggleSidebar",
  CHANGE_CURRENT_CATEGORY_FILTER = "changeCurrentCategoryFilter",
  ALERT_NOTYF = "alertNotyf",
  VERIFY_CODE = "verifyCode",
  RESET_PASSWORD = "resetPassword",
  UPDATE_USER_PHONE = "updateUserPhone",
  MOBILE_LOGIN = "mobileLogin",
  MOBILE_REGISTER = "mobileRegister",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_TOGGLE_SIDEBAR = "setToggleSidebar",
  SET_CURRENT_CATEGORY_FILTER = "setCurrentCategoryFilter",
  SET_AUTH_PROFILE = "setAuthProfile",
  TOGGLE_GLOBAL_LOADING = "toggleGlobalLoading",
  SET_MENU_TYPE = "setMenuType",
  REFRESH_CART_DATA = "refreshCartData",
  SET_STORE_DATA = "setStoreData",
  SET_CART_UUID = "setCartUuid",
  REFRESH_CLEAR_WISHLIST = "refreshClearWishlist",
  SET_CURRENT_SHIPPING_ADDRESS = "setCurrentShippingAddress",
}

export { Actions, Mutations };
