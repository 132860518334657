import { loadMessage } from "./i18n";
import { defineComponent, onMounted, nextTick, onBeforeMount, watch, } from "vue";
import { useStore } from "vuex";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { Mutations } from "@/store/enums/StoreEnums";
import { asyncLocalStorage, getCartList, setCartTokenHeader, } from "./core/helpers/custom";
import { incrementCounter } from "./api/CounterApi";
export default defineComponent({
    name: "app",
    setup: function () {
        var store = useStore();
        onBeforeMount(function () {
            asyncLocalStorage.getItem("lang").then(function (result) {
                // loadMessage(localStorage.getItem("lang") ?? "en");
                loadMessage(result !== null && result !== void 0 ? result : "km");
            });
        });
        watch(function () { return store.getters.isUserAuthenticated; }, function (updated) {
            var _a, _b;
            if (updated) {
                getCartList();
                store.commit(Mutations.SET_CART_UUID, "");
                localStorage.removeItem("cartUuid");
            }
            else {
                setCartTokenHeader((_b = (_a = store.getters) === null || _a === void 0 ? void 0 : _a.getStoreData) === null || _b === void 0 ? void 0 : _b.id);
            }
        });
        onMounted(function () {
            /**
             * this is to override the layout config using saved data from localStorage
             * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
             */
            store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
            incrementCounter({ type: "Visitor" });
            nextTick(function () {
                initializeComponents();
            });
        });
    },
});
