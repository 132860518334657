import ApiService from "@/core/services/ApiService";

export const addProductToCart = async (payload) => {
  const response = await ApiService.post(`/carts/add`, payload);
  return response;
};

export const cartList = async (payload) => {
  return await ApiService.query(`/carts`, payload);
};

export const emptyCart = async (payload) => {
  return await ApiService.post(`/carts/empty`, payload);
};

export const removeProductFromCart = async (payload) => {
  return await ApiService.post(`/carts/remove`, payload);
};

export const shippingMethodList = async (payload) => {
  return await ApiService.query(`/carts/shipping-methods`, payload);
};

export const applyShippingMethod = async (payload) => {
  return await ApiService.post(`/carts/apply-shipping`, payload);
};

export const applyCoupon = async (payload) => {
  return await ApiService.post(`/carts/apply-coupon`, payload);
};

export const checkout = async (payload) => {
  return await ApiService.post(`/carts/checkout`, payload);
};

export const qtyAdjustment = async (payload) => {
  return await ApiService.post(`/carts/qty-adjustment`, payload);
};

export const generateQrcode = async (payload) => {
  return await ApiService.post(`carts/create-qrcode`, payload);
};
