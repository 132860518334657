import isAuth from "@/middleware/isAuth";
import { RouteRecordRaw } from "vue-router";

const routeList: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
    name: "home",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/account",
        name: "account",
        redirect: "/account/settings",
        component: () => import("@/views/account/Account.vue"),
        meta: {
          isAuth: isAuth,
        },
        children: [
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/account/Settings.vue"),
          },
          {
            path: "address",
            name: "account-address-index",
            redirect: () => {
              return { name: "account-address-listing" };
            },
            component: () => import("@/views/account/address/Index.vue"),
            children: [
              {
                path: "listing",
                name: "account-address-listing",
                component: () => import("@/views/account/address/Listing.vue"),
              },
              {
                path: "create",
                name: "account-address-create",
                component: () => import("@/views/account/address/Form.vue"),
              },
              {
                path: "edit/:addressId",
                name: "account-address-edit",
                component: () => import("@/views/account/address/Form.vue"),
              },
            ],
          },
          {
            path: "wishlist",
            name: "account-wishlist",
            component: () => import("@/views/account/Wishlist.vue"),
          },
          {
            path: "promotion",
            name: "account-promotion",
            component: () => import("@/views/product/Promotion.vue"),
          },
          {
            path: "order",
            name: "account-order-index",
            redirect: () => {
              return { name: "account-order-listing" };
            },
            component: () => import("@/views/account/order/Index.vue"),
            children: [
              {
                path: "listing",
                name: "account-order-listing",
                component: () => import("@/views/account/order/Listing.vue"),
              },
              {
                path: "show/:orderId",
                name: "account-order-show",
                component: () => import("@/views/account/order/Show.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "checkout",
        name: "checkout",
        component: () => import("@/views/account/Checkout.vue"),
      },
      {
        path: "static-ordered-page",
        name: "static-ordered-page",
        component: () => import("@/views/misc/StaticOrderedPage.vue"),
      },
      {
        path: "/product",
        name: "product",
        redirect: () => {
          return { name: "home" };
        },
        component: () => import("@/views/product/Index.vue"),
        children: [
          {
            path: "/product/:productId",
            name: "product-show",
            component: () => import("@/views/product/Show.vue"),
          },
        ],
      },
      {
        path: "/store",
        name: "store",
        component: () => import("@/views/misc/Store.vue"),
      },
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/home/Home.vue"),
        meta: {
          isCategory: true,
        },
      },
      {
        path: "/category/:categoryId",
        name: "category-show",
        meta: {
          isCategory: true,
        },
        component: () => import("@/views/category/Show.vue"),
      },
      {
        path: "/brand",
        name: "brand",
        redirect: () => {
          return {
            name: "brand-listing",
            params: {
              from: "Brand",
            },
          };
        },
        component: () => import("@/views/brand/Index.vue"),
        meta: {
          isBrand: true,
        },
        children: [
          {
            path: "listing",
            name: "brand-listing",
            component: () => import("@/views/home/Home.vue"),
          },
          {
            path: ":brandId",
            name: "brand-show",
            component: () => import("@/views/brand/Show.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    name: "auth",
    redirect: "/auth/login",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("@/views/auth/email/Login.vue"),
      },
      {
        path: "register",
        name: "register",
        component: () => import("@/views/auth/email/Register.vue"),
      },
      {
        path: "password-reset",
        name: "password-reset",
        component: () => import("@/views/auth/email/PasswordReset.vue"),
        meta: {
          isAuth: !isAuth,
        },
      },
      {
        path: "mobile-login",
        name: "mobile-login",
        component: () => import("@/views/auth/mobile/Login.vue"),
      },
      {
        path: "mobile-register",
        name: "mobile-register",
        component: () => import("@/views/auth/mobile/Register.vue"),
      },
    ],
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/components/BaseTestPage.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

export default routeList;
