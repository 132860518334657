import { createRouter, createWebHistory } from "vue-router";
import routeList from "@/router/routeList";
import isAuth from "@/middleware/isAuth";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";

const router = createRouter({
  history: createWebHistory(),
  routes: routeList,
});

router.beforeEach((to, _from, next) => {
  if (to.meta.isAuth) {
    store.commit(Mutations.SET_MENU_TYPE, "Account");
  } else {
    store.commit(Mutations.SET_MENU_TYPE, "Category");
  }

  isAuth({ to, next });
});

export default router;
