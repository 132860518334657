import { INotyfNotificationOptions, Notyf } from "notyf";
import "notyf/notyf.min.css";

const notyf = new Notyf({
  duration: 1250,
  dismissible: false,
  ripple: false,
  types: [
    {
      type: "error",
      position: { x: "right", y: "top" },
    },
    {
      type: "success",
      position: { x: "center", y: "bottom" },
    },
  ],
});

export default function useNotyf() {
  return {
    success: (message: string | Partial<INotyfNotificationOptions>) => {
      notyf.dismissAll();
      notyf.success(message);
    },
    error: (message: string | Partial<INotyfNotificationOptions>) => {
      notyf.error(message);
    },
  };
}
