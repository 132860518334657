import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import emitter from "tiny-emitter/instance";
import * as interfaces from "@/globalInterface";
import { IUserAuthInfo, IUser } from "@/globalInterface";
import { notification } from "@/core/helpers/custom";
import useNotyf from "@/composable/useNotyf";
// import { useI18n } from "vue-i18n/index";
// import i18n from "@/core/plugins/i18n";

@Module
export default class AuthModule extends VuexModule implements IUserAuthInfo {
  errors = {};
  user = {} as IUser;
  isAuthenticated = !!JwtService.getToken();
  store = {} as interfaces.IStore;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): interfaces.IUser {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  get currentStore(): interfaces.IStore {
    return this.store;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user.data?.profile;
    this.errors = {};
    if (user.data?.access_token) {
      JwtService.saveToken(user.data?.access_token);
    }
  }

  @Mutation
  [Mutations.SET_AUTH_PROFILE](user) {
    this.isAuthenticated = true;
    this.user = user?.data;
    this.errors = {};
    this.store = user?.data.store;
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as interfaces.IUser;
    this.errors = [];
    JwtService.destroyToken();
    // ApiService.removeHeader();
  }

  @Action({ rawError: true })
  [Actions.LOGIN](credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("signin", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          notification({
            type: "success",
            message: "You have successfully logged in!",
          });
          // emitter.emit("loginSuccess", true);
          resolve(data);
        })
        .catch(({ response }) => {
          // emitter.emit("loginFail", true);
          reject(response);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.MOBILE_LOGIN](credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("signin/validate", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          notification({
            type: "success",
            message: "You have successfully logged in!",
          });
          // emitter.emit("loginSuccess", true);
          resolve(data);
        })
        .catch(({ response }) => {
          // emitter.emit("loginFail", true);
          reject(response);
        });
    });
  }

  @Action
  [Actions.LOGIN_BY_SOCIAL](credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("social-signin", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          notification({
            type: "success",
            message: "You've logged in successfully!",
          });
          // emitter.emit("loginSuccess", true);
          resolve(data);
        })
        .catch(({ response }) => {
          // emitter.emit("loginFail", true);
          reject(response);
        });
    });
  }

  @Action
  [Actions.UPDATE_USER](credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/profile/update", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          notification({
            type: "success",
            // message: data.data.message ?? "Success",
            message: "Success",
          });
          // emitter.emit("userProfileUpdateSuccess", true);
          resolve(data);
        })
        .catch(({ response }) => {
          // emitter.emit("userProfileUpdateFail", true);
          reject(response);
        });
    });
  }

  @Action
  [Actions.UPDATE_USER_PASSWORD](credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/profile/update-password", credentials)
        .then(({ data }) => {
          notification({
            type: "success",
            // message: data.message ?? "Success",
            message: "Success",
          });
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action
  [Actions.UPDATE_USER_PHONE](credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/profile/update-phone", credentials)
        .then(({ data }) => {
          notification({
            type: "success",
            // message: data.data.message,
            message: "Success",
          });
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.LOGOUT](notificationAlert = false) {
    // console.log(i18n.global.t("greet"));
    this.context.commit(Mutations.PURGE_AUTH);
    return new Promise((resolve) => {
      if (notificationAlert) {
        notification({
          type: "error",
          message: "You have successfully logged out!",
        });
        // localStorage.removeItem("currentStore");
      }
      resolve(true);
    });
  }

  @Action({ rawError: true })
  [Actions.REGISTER](credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("register", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          notification({
            type: "success",
            message: "You have successfully registered!",
          });
          resolve(data);
        })
        .catch((res) => {
          reject(res);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.MOBILE_REGISTER](credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("register/validate", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          notification({
            type: "success",
            message: "You have successfully registered!",
          });
          resolve(data);
        })
        .catch((res) => {
          reject(res);
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/forget-password/request", payload)
        .then(({ data }) => {
          notification({
            type: "success",
            message:
              // data?.message ?? "Your secret code has already been sent to your email!",
              "Your secret code has already been sent to your email!",
          });
          resolve("success");
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action
  [Actions.VERIFY_CODE](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/forget-password/validate-code", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          notification({
            type: "success",
            // message: data?.message ?? "Code Verify",
            message: "Code Verify",
          });
          resolve("success");
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action
  [Actions.RESET_PASSWORD](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/forget-password/update-password", payload)
        .then(({ data }) => {
          notification({
            type: "success",
            // message: data?.message ?? "Password Change successfully",
            message: "Password Change successfully",
          });
          resolve("success");
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          reject(response);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      // ApiService.setHeader();
      return new Promise((resolve, reject) => {
        ApiService.get("profile")
          .then(({ data }) => {
            this.context.commit(Mutations.SET_AUTH_PROFILE, data);
            return resolve(data);
          })
          .catch(({ response }) => {
            // this.context.commit(
            //   Mutations.SET_ERROR,
            //   response.data?.data?.errors
            // );
            this.context.commit(Mutations.PURGE_AUTH);
            return reject(response);
          });
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
