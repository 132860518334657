import { IStore } from "@/globalInterface";
import { Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Module, VuexModule } from "vuex-module-decorators";

interface IStoreInfo {
  toggleSidebarState: boolean;
  // storeId: number;
  cartUuid: string;
  clearWishlist: boolean;
  currentShippingAddress: number | null;
}

interface IApp {
  topBarLoading?: boolean;
  menuType: string;
}

interface ICart {
  sub_total: number;
  discount: number;
  total: number;
  shipping: any[];
  coupon: any[];
  products: IProduct[];
}

interface IProduct {
  cart_id: number;
  id: number;
  name: string;
  code: string;
  price: number;
  quantity: number;
  options: IOption[];
}

interface IOption {
  name: string;
  value: IValue;
}

interface IValue {
  name: string;
  price: number;
}

@Module
export default class AppModule extends VuexModule implements IStoreInfo, IApp {
  toggleSidebarState = false;
  currentCategoryFilter = { id: "-1", name: "All Category" };
  topBarLoading = false;
  menuType = "Home";
  cart = {} as ICart;
  storeData = {} as IStore;
  cartUuid = "";
  clearWishlist = false;
  currentShippingAddress = null;

  get toggleSidebarStateValue() {
    return this.toggleSidebarState;
  }

  get globalLoading() {
    return this.topBarLoading;
  }

  get currentMenuType() {
    return this.menuType;
  }

  get currentCart() {
    return this.cart;
  }

  get getStoreData() {
    return this.storeData;
  }

  get getCartUuid() {
    return this.cartUuid;
  }

  get isClearWishlist() {
    return this.clearWishlist;
  }

  get getCurrentShippingAddress() {
    return this.currentShippingAddress;
  }

  @Mutation
  [Mutations.TOGGLE_GLOBAL_LOADING](payload): void {
    this.topBarLoading = payload;
  }

  @Mutation
  [Mutations.SET_TOGGLE_SIDEBAR](payload): void {
    this.toggleSidebarState = payload;
  }

  @Mutation
  [Mutations.SET_MENU_TYPE](payload): void {
    this.menuType = payload;
  }

  @Mutation
  [Mutations.REFRESH_CART_DATA](payload): void {
    this.cart = payload;
  }

  @Mutation
  [Mutations.SET_STORE_DATA](payload): void {
    this.storeData = payload;
  }

  @Mutation
  [Mutations.SET_CART_UUID](payload): void {
    this.cartUuid = payload;
  }

  @Mutation
  [Mutations.REFRESH_CLEAR_WISHLIST](payload): void {
    this.clearWishlist = payload;
  }

  @Mutation
  [Mutations.SET_CURRENT_SHIPPING_ADDRESS](payload): void {
    this.currentShippingAddress = payload;
  }
}
