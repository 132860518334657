import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import store from "@/store";
import useNotyf from "@/composable/useNotyf";
import { translate } from "../helpers/custom";
// import i18n from "@/i18n";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    const notification = useNotyf();
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);

    ApiService.vueInstance.axios.interceptors.request.use(
      (config) => {
        if (JwtService.getToken()) {
          config.headers.common[
            "Authorization"
          ] = `Bearer ${JwtService.getToken()}`;
        }
        config.baseURL = `${process.env.VUE_APP_API_URL}/api/v1/${store.getters.getStoreData.id}`;
        config.headers.common["Cart-Uuid"] = store.getters.getCartUuid;
        config.headers.common["Content-Type"] = "application/json";
        config.headers.common["Accept"] = "application/json";
        // config.headers.common[
        //   "Authorization"
        // ] = `Bearer ${JwtService.getToken()}`;
        return config;
      },
      (error) => Promise.reject(error)
    );

    ApiService.vueInstance.axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const code = error.response?.status;
        let message = "";

        switch (code) {
          case 400:
            message = error?.response?.data?.message ?? "Bad Requests";
            break;
          case 401:
            message = "";
            break;
          case 404:
            message = error?.response?.statusText ?? "Not Found";
            break;
          case 422:
            message = (Object.values(error?.response?.data?.errors ?? {})[0] ??
              "Unprocessable Content") as string;
            break;
          case 429:
            message = error?.response?.data?.message ?? "Too Many Requests";
            break;
          case 500:
            message = error?.response?.data?.message ?? "Internal Server Error";
            break;
          default:
            message =
              error?.response?.data?.message ??
              "Error, Please notify the administrator";
            break;
        }

        if (error.toJSON().message === "Network Error") {
          console.error(error.toJSON().message);
        }

        if (message != "") notification.error(translate(message));

        return Promise.reject(error.response);
      }
    );
  }

  // /**
  //  * @description set the default HTTP request headers
  //  */
  // public static setHeader(): void {
  //   ApiService.vueInstance.axios.defaults.headers.common[
  //     "Authorization"
  //   ] = `Bearer ${JwtService.getToken()}`;
  //   ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
  //     "application/json";
  // }

  public static removeHeader(): void {
    console.log(
      "%cApiService.ts line:99 ApiService",
      "color: #007acc;",
      ApiService
    );
    ApiService.vueInstance.axios.defaults.headers.common["Authorization"] = "";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
  public static instance() {
    return ApiService.vueInstance.axios;
  }
}

export default ApiService;
